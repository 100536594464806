import { useFilePicker } from 'use-file-picker';
import { Button, Box, Typography, Grid } from '../../mui';

const lightInput = {
  borderColor: '#a4a4a4',
  mt: 3,
  borderRadius: '10px',
  marginTop: '10px',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  backgroundColor: '#ffffff',
};

const darkInput = {
  borderColor: '#a4a4a4',
  mt: 3,
  borderRadius: '10px',
  marginTop: '10px',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  backgroundColor: '#282828',
};

const FilePicker = ({ file, setFile, title, dimensions }) => {
  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: '.png',
    multiple: false,
    readAs: 'DataURL',
    onFilesSelected: ({ plainFiles }) => {
      setFile({ image: plainFiles[0], upload: true });
    },
  });

  if (loading) {
    return (
      <Typography varient="body2" sx={{ px: '8px', py: '6px' }}>
        Loading...
      </Typography>
    );
  }

  return (
    <>
      <Typography varient="body2" sx={{ py: '6px' }}>
        {title}
      </Typography>
      <Box style={localStorage.getItem('theme') === 'DARK' ? darkInput : lightInput}>
        <Grid container>
          <Grid item xs={5}>
            <Button varient="contained" color="primary" onClick={() => openFileSelector()}>
              Select file
            </Button>
          </Grid>
          <Grid item xs={7}>
            <Typography
              varient="body2"
              sx={{
                px: '8px',
                py: '6px',
                height: '33px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                wordWrap: 'break-word',
              }}
            >
              {filesContent.length ? filesContent[0]?.name : file?.imageName ?? `No file selected`}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography
        component="h5"
        sx={{
          py: '6px',
          fontSize: '0.7rem',
        }}
      >
        Note: To avoid distortion, please upload the image with the correct dimensions. ({dimensions})
      </Typography>
      <Box
        component="img"
        sx={{ mt: 2, height: '50px', borderRadius: '20px', objectFit: 'contain', width: '100%' }}
        alt={filesContent.length ? filesContent[0]?.name : file?.imageName ?? null}
        src={filesContent.length ? filesContent[0]?.content : file ? file.image : null}
      />
    </>
  );
};

export default FilePicker;
